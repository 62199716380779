import { FC, useEffect, useState } from "react";
import FilialsMap from "../filials-map/filials-map";
import Filters from "../filters/filters";
import  { Medcenter, Program } from "../../types/models/dms-contract";
import { api } from "../../constants/constants";
import useParams from "../../hooks/use-params";
import getProgramsWithLpuOnly from "../../helpers/get-programs-with-lpu-only";
import "./index.css";
import useForm from "../../hooks/use-form";
import { LpuFilialExtended } from "../../types/render/lpu-filial-extended";

interface MainProps {}

const Main: FC<MainProps> = () => {
  const form = useForm()
  const [contractIsn, subjectIsn] = useParams();
  const [programs, setPrograms] = useState<Program[]>([]);
  const [lpuList, setLpuList] = useState<(LpuFilialExtended)[]>([]);

  const componentDidMount = async () => {
    try {
      let programs = await api.getDmsContractPrograms(contractIsn, subjectIsn);
      programs = getProgramsWithLpuOnly(programs)
      setPrograms(programs)
      form.setFieldValue('program', 0)
    } catch (e) {
      // TODO: show error
    }
  };

  useEffect(() => {
    componentDidMount();
  }, []);

  const onMedcenterChange = async (medcenters: Medcenter[]) => {
    if (!programs) return;

    if (medcenters.length > 0) {
      const promises = medcenters.map((medcenter) => {
        return api.getLpuList(medcenter.isn);
      });

      const results = await Promise.all(promises);

      const newState = results.reduce((list: LpuFilialExtended[], lpuList, i) => {
        if (lpuList) {
          const listByMedcenter = lpuList.map(item => ({...item, franchtariff: medcenters[i].franchtariff})) 
          list.push(...listByMedcenter);
        }
        return list;
      }, []);

      setLpuList(newState);
    } else {
      setLpuList([]);
    }
  };

  return (
    <div className={"main"}>
      <div className={"main__filials-map-col"}>
        <FilialsMap lpuList={lpuList} programs={programs} />
      </div>
      <div className={"main__filters-col"}>
        <Filters
          programs={programs}
          lpuList={lpuList}
          onMedcenterChange={onMedcenterChange}
        />
      </div>
    </div>
  );
};

export default Main;
