import { Form, Select } from "antd";
import { FC } from "react";
import useFormWatch from "../../../hooks/filters-form/use-watch";
import {
  Medcenter as MedcenterInterface,
  Program,
} from "../../../types/models/dms-contract";
import useForm from "../../../hooks/use-form";
import Text from "antd/es/typography/Text";

export interface MedcenterProps {
  programs: Program[];
  onChange: (medcenters: MedcenterInterface[]) => void;
}

const Medcenter: FC<MedcenterProps> = ({
  programs,
  onChange: onChangeFromProps,
}) => {
  const form = useForm();
  const program = useFormWatch(["program"]);
  const medcenters = programs[program]?.medcenters || [];

  const onChange = (indexes: number[]) => {
    let selectedMedcenters: MedcenterInterface[];

    if (indexes.at(-1) === -1) {
      selectedMedcenters = medcenters;
      form.setFieldValue("medcenter", [-1]);
    } else {
      const filteredIndexes = indexes.filter((index) => index !== -1);
      form.setFieldValue("medcenter", filteredIndexes);
      selectedMedcenters = filteredIndexes.map((index) => medcenters[index]);
    }

    onChangeFromProps(selectedMedcenters);
  };

  return (
    <Form.Item name={"medcenter"} label={"Медцентр"}>
      <Select mode={"multiple"} allowClear onChange={onChange} filterOption={(inputValue, option) => {
        console.log(option)
        return true
                                    // return (
                                    //     option?.title
                                    //         .toUpperCase()
                                    //         .indexOf(inputValue.toUpperCase()) !== -1
                                    // )
                                }}>
        <Select.Option value={-1}>Любое</Select.Option>
        {medcenters.map((item, i) => {
          return (
            <Select.Option value={i} key={item.isn}>
              {item.name}
              {
                item.franchtariff !== "0" ? 
                  <>
                    <br/>
                    <Text style={{fontSize: 12}} type={"secondary"}>Франшиза: {item.franchtariff}%</Text>
                  </> : 
                  null
              }
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default Medcenter;
